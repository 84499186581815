<template>
  <div class="q-pb-md">
    <q-form
      @submit="onSubmit"
      ref="formRef_step1_1"
      @reset="onReset"
      class="row"
    >
      <div class="col-3 q-px-sm">
        <q-input
          :readonly="readonly"
          outlined
          dense
          :disable="blockPc"
          ref="postcodeRef"
          :modelValue="postcode"
          @change="postcode = $event"
          label="Postcode *"
          lazy-rules
          mask="####AA"
          :rules="validate('postcode')"
        />
      </div>
      <div class="col-3 q-px-sm">
        <q-input
          :readonly="readonly"
          outlined
          dense
          ref="housenumberRef"
          :disable="blockPc"
          :modelValue="housenumber"
          @change="housenumber = $event"
          label="Huisnummer *"
          lazy-rules
          :rules="validate('housenumber')"
        />
      </div>
      <div class="col-3 q-px-sm">
        <q-input
          :readonly="readonly"
          outlined
          dense
          :disable="blockPc"
          v-model="housenumberExtension"
          label="Huisnummer toevoeging"
        />
      </div>
      <div :class="colSize" class="col-3">
        <q-btn
          :disabled="readonly"
          style="width: 100%"
          no-caps
          label="Zoeken"
          type="submit"
          color="primary"
          :loading="loading.address ?? false"
        />
      </div>
    </q-form>
    <address-options
      v-show="showAddressOptions"
      @update:address="updateAddress()"
    ></address-options>
    <installation-details
      v-if="
        (view == 'retention' && showInstallationDetails) ||
        ((showInstallationDetails || (manualEan && installationCount)) &&
          !addressUnknown &&
          !showAddressOptions)
      "
    ></installation-details>
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import InstallationDetails from "../components/InstallationDetails.vue";
import AddressOptions from "../components/AddressOptions.vue";
import { validate, validator } from "../plugins/validation.js";
import store from "../store";

export default {
  components: {
    InstallationDetails,
    AddressOptions,
  },

  setup() {
    const $q = useQuasar();
    const $store = useStore();
    const errorHousenumber = ref(false);

    const housenumberRef = ref(null);
    const postcodeRef = ref(null);

    const loading = computed({
      get() {
        return $store.getters.loading ?? false;
      },
      set(newVal) {
        $store.dispatch("setLoading", {
          key: newVal[0],
          value: newVal[1],
        });
      },
    });

    const colSize = computed(() => {
      return $q.screen.lt.md ? "q-px-sm" : "q-px-xl";
    });

    const view = computed(() => $store.getters.view);

    const installationCount = computed(() => {
      if (
        typeof $store.getters.installationdetails.gas == "undefined" ||
        typeof $store.getters.installationdetails.electricity == "undefined"
      ) {
        return 0;
      }

      return (
        $store.getters.installationdetails.gas.length +
        $store.getters.installationdetails.electricity.length
      );
    });

    const manualEan = computed(() => {
      return view.value == "energyportal"
        ? true
        : $store.getters?.manualEan ?? false;
    });

    const blockPc = computed(() => {
      if ($store.getters.projectSpecific["Blockpc"] != "true") {
        return false;
      }

      return true;
    });

    const postcode = computed({
      get() {
        return $store.getters.record?.Postcode ?? "";
      },
      set(newVal) {
        if (postcode.value != "" || postcode.value != newVal) {
          $store.dispatch("setProjectSpecificValues", {
            values: {
              adresInformatieAangepast: "Ja",
            },
          });
        }
        $store.dispatch("updateRecord", {
          key: "Postcode",
          value: newVal,
          validated: validator("postcode", newVal),
        });
      },
    });

    const street = computed({
      get() {
        return $store.getters.record?.Straat ?? "";
      },
      set(newVal) {
        if (street.value != "" || street.value != newVal) {
          $store.dispatch("setProjectSpecificValues", {
            values: {
              adresInformatieAangepast: "Ja",
            },
          });
        }
        $store.dispatch("updateRecord", { key: "Straat", value: newVal });
      },
    });

    const housenumber = computed({
      get() {
        return $store.getters.record?.Huisnummer ?? "";
      },
      set(newVal) {
        if (housenumber.value != "" || housenumber.value != newVal) {
          $store.dispatch("setProjectSpecificValues", {
            values: {
              adresInformatieAangepast: "Ja",
            },
          });
        }
        $store.dispatch("updateRecord", {
          key: "Huisnummer",
          value: newVal,
          validated: validator("housenumber", newVal),
        });
      },
    });

    const housenumberExtension = computed({
      get() {
        return $store.getters.record?.HuisnummerToev ?? "";
      },
      set(newVal) {
        if (
          housenumberExtension.value != "" ||
          housenumberExtension.value != newVal
        ) {
          $store.dispatch("setProjectSpecificValues", {
            values: {
              adresInformatieAangepast: "Ja",
            },
          });
        }
        $store.dispatch("updateRecord", {
          key: "HuisnummerToev",
          value: newVal,
        });
      },
    });

    const showInstallationDetails = computed(() => {
      if (view.value == "retention") {
        if (
          store.getters.projectSpecific["verbruik electra"] ||
          store.getters.projectSpecific["verbruik electra 2"] ||
          store.getters.projectSpecific["verbruik gas"]
        ) {
          return true;
        }
      }

      if (
        (typeof $store.getters.installationdetails.gas == "undefined" ||
          $store.getters.installationdetails.gas == null) &&
        (typeof $store.getters.installationdetails.electricity == "undefined" ||
          $store.getters.installationdetails.gas != null)
      ) {
        return 0;
      }

      return (
        $store.getters.installationdetails?.gas.length +
        $store.getters.installationdetails?.electricity.length
      );
    });

    const addressUnknown = computed(() => {
      if (
        $store.getters.record?.Woonplaats == null ||
        $store.getters.record?.Straat == null
      ) {
        return true;
      }
      return false;
    });

    const showAddressOptions = computed(() => {
      if (
        typeof $store.getters.addressOptions == "undefined" ||
        $store.getters.addressOptions == null ||
        $store.getters.addressOptions.length <= 1 ||
        !searchedAddress.value
      ) {
        return false;
      }
      return true;
    });
    const accept = ref(false);
    const searchedAddress = computed({
      get() {
        return $store.getters.searchedAddress ?? false;
      },
      set(newVal) {
        $store.dispatch("setSearchedAddress", newVal);
      },
    });

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    return {
      postcode,
      street,
      housenumber,
      housenumberExtension,
      showInstallationDetails,
      showAddressOptions,
      accept,
      loading,
      housenumberRef,
      postcodeRef,
      blockPc,
      searchedAddress,
      manualEan,
      errorHousenumber,
      addressUnknown,
      readonly,
      colSize,
      view,
      installationCount,
      onReset() {
        accept.value = false;
      },
    };
  },
  methods: {
    updateAddress() {
      this.$store.dispatch("setSearchedAddress", false);
    },
    onSubmit() {
      this.loading = ["address", true];
      this.housenumberRef.validate();
      this.postcodeRef.validate();
      if (this.housenumberRef.hasError || this.postcodeRef.hasError) {
        return;
      }
      this.$store.dispatch("setSearchedAddress", true);
      this.$store.dispatch("setProjectSpecificValues", {
        values: {
          gas: "",
          electricity: "",
          "EAN Gas": "",
          "EAN Elektra": "",
          AddressOptions: null,
        },
      });
      this.$store.dispatch("setManualEan", false);
      this.$store.dispatch("send_message", {
        message: "get_house_number_extensions",
      });
      this.$store.dispatch("send_message", { message: "start_sac_flow" });
    },
    validate,
  },
};
</script>

<style lang="sass"></style>
