<template>
  <div class="q-pb-md">
    <q-markup-table flat>
      <thead>
        <tr>
          <th class="text-left">Straat</th>
          <th class="text-left">Huisnummer</th>
          <th class="text-left">HuisnummerToevoeging</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="address in addressOptions" :key="address">
          <td class="text-left">{{ address.street }}</td>
          <td class="text-left">{{ address.house_number }}</td>
          <td class="text-left">{{ address.house_number_extension }}</td>
          <td class="text-right">
            <q-btn
              no-caps
              @click="
                () => {
                  updateAddress(address);
                }
              "
              color="primary"
              label="Kies"
            />
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  emits: ["update:address"],
  setup() {
    const $q = useQuasar();
    const $store = useStore();

    // const residence = ref(true);
    // const moving = ref(false);

    const view = computed(() => {
      return $store.getters.view;
    });

    const loading = computed(() => {
      return $store.getters.loading ?? false;
    });

    const addressOptions = computed(() => {
      return $store.getters.addressOptions ?? [];
    });

    return {
      addressOptions,
      loading,
      $q,
      view,
    };
  },
  methods: {
    updateAddress(address) {
      this.$store.dispatch("updateRecord", {
        key: "Straat",
        value: address.street,
      });
      this.$store.dispatch("updateRecord", {
        key: "Huisnummer",
        value: address.house_number,
      });
      this.$store.dispatch("updateRecord", {
        key: "HuisnummerToev",
        value: address.house_number_extension,
      });
      this.$store.dispatch("updateRecord", {
        key: "Woonplaats",
        value: address.city,
      });
      this.$store.dispatch("updateRecord", {
        key: "Postcode",
        value: address.postcode,
      });

      this.$store.dispatch("send_message", { message: "start_bac_flow" });
      this.$store.dispatch("send_message", { message: "start_sac_flow" });
      this.$emit("update:address");
    },
  },
};
</script>

<style lang="sass">
th
  font-weight: 700 !important
</style>
